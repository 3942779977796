document.addEventListener("DOMContentLoaded", () => {
  document.querySelectorAll(".has-under-image").forEach((img) => {
    const hi = img.parentElement.querySelector(".hidden-image");
    if (hi) {
      img.addEventListener("click", () => {
        hi.style["z-index"] = 1;
        hi.classList.add("show");
      });
      hi.addEventListener("click", () => {
        hi.classList.remove("show");
        const transitionDuration =
          parseFloat(getComputedStyle(hi).transitionDuration) * 1000;
        setTimeout(() => {
          hi.style["z-index"] = -1;
        }, transitionDuration);
      });
    }
  });
});
